import React from "react"

import Navigation from "../components/Navigation/Navigation"
import Context from "../Provider/ContextProvider"

const Index = (props) => {
	return (
		<Context>
			<Navigation />
			<div>{props.children}</div>
		</Context>
	)
}

export default Index
