import React from "react"

import StoreContext from "../../StoreContext/index"
import { getTotalItems } from "./HelperFns/index"
import icon from "../../images/blackCart.png"
import styled from "@emotion/styled"
import { triggerCart } from "../Cart/HelperFns"

import Wrapper from "./Wrapper/index"

var { useState, useContext, createRef } = React

const Cart = () => {
	var [store, setStore] = useContext(StoreContext)
	var { added, isCartVisible } = store
	//  toggle the cart
	var [isVisible, setVisible] = useState(false)

	var totalItems = getTotalItems(added)
	var cartRef = createRef()

	var toggleCart = () => {
		triggerCart(setStore)
	}

	var WrapperProps = {
		isCartVisible,
		isVisible,
		setVisible,
		added,
	}

	return (
		<>
			<StyledCartIconContainer
				ref={cartRef}
				sx={{
					zIndex: 10,
				}}
				onClick={toggleCart}
				className={isCartVisible ? "showCart" : "hideCart"}
			>
				<StyledShoppingBagIcon>
					{totalItems !== 0 && <CartCount>{totalItems}</CartCount>}
				</StyledShoppingBagIcon>
			</StyledCartIconContainer>
			<Wrapper onSetVisible={setVisible} {...WrapperProps} />
		</>
	)
}

export default Cart

const StyledCartIconContainer = styled.div`
	cursor: pointer;
	z-index: 999;
	flex-basis: 0;
	margin-bottom: 4px;
`

const StyledShoppingBagIcon = styled.span`
	background-image: url(${icon});
	background-repeat: no-repeat;
	background-size: cover;
	width: 12px;
	height: 16px;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`

const CartCount = styled.span`
	padding-top: 3px;
	font-size: 0.65rem;
	color: white;
`
