import React from 'react'
import RemoveProduct from "../Buttons/RemoveProduct"
import { getSrc } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import * as styles from "./lineItems.module.scss"

import {
	variantStyles,
} from "./styles"

import { v4 as uuidv4 } from "uuid"

//this is the popup
const LineItems = (props) => {
	var { added } = props

	return added.map((item, i) => {
		
		let imgSrc = getSrc(item.images[0]);
		return (
			<div className={styles.container} key={uuidv4()}>
				<div className={styles.productImgContainer}>
					{item.images[0].localFile ? (
						<img
							className={styles.lineImage}
							// fluid={item.images[0].localFile.childImageSharp.fluid}
							// image={data.file.childImageSharp.gatsbyImageData}
							src={imgSrc}
						/>
					) : (
						<img className={styles.lineImageSafari} src={imgSrc} alt="" />
					)}
				</div>

				<div className={styles.qtyContainer}>
					<StyledQuantity>
						{item.chosenVariant.quantity} x EUR {item.chosenVariant.price}
					</StyledQuantity>
				</div>
				<StyledTitleContainer>
					<StyledItemTitle>{item.title}</StyledItemTitle>
					{renderVariantTitle(item)}

					<RemoveProduct shopifyId={item.chosenVariant.shopifyId} />
				</StyledTitleContainer>
			</div>
		)
	})
}

export default LineItems

const renderVariantTitle = (item) => {
	return item.variants.length > 1 ? (
		<li sx={variantStyles}>{item.chosenVariant.title}</li>
	) : null
}

const StyledTitleContainer = styled.div`
	margin: 16px 0;
	position: absolute;
	left: 70%;
`
const StyledItemTitle = styled.p`
	margin: 0;
	font-size: 15px;
`

const StyledQuantity = styled.p`
	font-size: 15px;
`
