import React from "react"
import { Link } from "gatsby"
import Cart from "../Cart/index"
import styled from "@emotion/styled"
import {header, logoLink, monogram, cart, aboutTitle, aboutLink, activeStyle, styledText, logoContainer} from "./navigation.module.scss"
import { useState, useEffect } from "react"

const Navigation = () => {
	const isClient = typeof window === "object"

	let currentLocation = isClient ? window.location : "/"
	const [active, setActive] = useState("")

	useEffect(() => {
		if (
			currentLocation.pathname === "/about/" ||
			currentLocation.pathname === "/about"
		) {
			setActive("active")
		} else {
			setActive("")
		}
	}, [currentLocation.pathname])

	const toggleAbout = () => {
		if (
			!currentLocation.pathname === "/about/" ||
			!currentLocation.pathname === "/about"
		) {
			setActive(active === "" ? "active" : "")
		}
	}
	return (
		<header className={header}>
			<Link className={logoLink} to="/">
				<div className={logoContainer}>
					<span className={monogram} alt="" >∆</span>
					<p className={styledText}>IN'N'OUT FRAGRANCES</p>
				</div>
			</Link>
			<Link className={aboutLink} to="/about">
				<span
					onClick={toggleAbout}
					className={`${aboutTitle} ${
						active === "active" ? activeStyle : ""
					}`}
				>
					About
				</span>
			</Link>

			<div className={cart}>
				<Cart  />
			</div>
		</header>
	)
}
export default Navigation



const Logo = styled.h1`
`

// const Logo = styled.img`
// 	width: 4.5vh;
// 	max-width: none;
// 	vertical-align: sub;
// 	margin-right: 10px;
// `

