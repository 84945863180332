export const components = {
	layout: {
		full: {
			maxWidth: 5,
			margin: `auto`,
		},
		medium: {
			width: 2,
			padding: 2,
		},
		small: {
			width: 1,
			padding: 1,
		},
	},
	buttons: {
		secondary: {
			cursor: "pointer",
			fontSize: 15,
			fontWeight: "bold",
			color: "primary",
			borderRadius: 0,
			border: "2px solid black",
			backgroundColor: "white",
			paddingTop: ".1rem",
			paddingBottom: ".1rem",
			paddingLeft: ".9rem",
			paddingRight: ".9rem",
			transition: (props) => `${props.transitions[0]}s`,
			"&:hover": {
				background: (props) => props.colors.primary,
				color: "white",
				transition: (props) => `${props.transitions[0]}s`,
			},
		},
		disabled: {
			cursor: "pointer",
			fontWeight: "bold",
			color: "white",
			backgroundColor: "grays.4",
			padding: (props) => `${props.space[1]}px ${props.space[2]}px`,
		},
	},
	forms: {
		medium: {
			padding: 1,
		},
	},
}
