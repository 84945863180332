import React from "react"
import styled from "@emotion/styled"

import { removeCartItem } from "../HelperFns/index"
import StoreContext from "../../../StoreContext/index"

var { useContext } = React

const RemoveProduct = (props) => {
	//  get access to all of my current items in my cart through the store.
	var [store, setStore] = useContext(StoreContext)
	return (
		<StyledRemoveText onClick={() => removeCartItem(store, setStore, props)}>
			Remove
		</StyledRemoveText>
	)
}

export default RemoveProduct

const StyledRemoveText = styled.p`
	font-family: Helvetica52;
	font-size: 12px;
	font-weight: bold;
	cursor: pointer;
	transition: 0.25s;
	margin: 0;
	&:hover {
    	text-decoration: underline;
	}
`
