export const variantStyles = {
	fontSize: 0,
	color: "grays.3",
	lineHeight: 2,
}

export const titleStyles = {
	color: "text",
	fontSize: 1,
	lineHeight: 2,
}

export const itemWrapperStyles = {
	display: "flex",
	flexFlow: "column nowrap",
	width: "50%",
}

export const productPriceStyles = {
	color: "grays.3",
	marginLeft: "auto",
}
