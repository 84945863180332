import { Link } from "gatsby"

import { getTotalPrice } from "../HelperFns/index"

import CheckoutButton from "../Buttons/CheckoutButton"
import LineItems from "../LineItems/LineItems"
import styled from "@emotion/styled"
import React, { useEffect } from "react"

import * as styles from "./wrapper.module.scss"

const Wrapper = (props) => {
	var { isCartVisible, isVisible, setVisible, added } = props
	var totalPrice = getTotalPrice(added)
	// const [cartLength, setCartLength] = useState(added.length)
	useEffect(() => {
		setVisible(false)
	}, [added.length])
	return (
		<StyledCart
			// onClick={() => setVisible(!isVisible)}
			// check to see if cart has been triggered by a click or adding a product.
			// show or hide the cart depending on the user action.
			className={isVisible || isCartVisible ? "showCart" : "hideCart"}
		>
			{/* pass "added" state to Cart Item */}
			{added.length > 0 ? <LineItems added={added} /> : null}
			<StyledButtonContainer>
				{added.length > 0 ? (
					<>
						<Link to="/cart">
							<button
								className={styles.cartBtn}
								onClick={() => {
									setVisible(false)
								}}
							>
								View Bag
							</button>
						</Link>
						<div className={styles.checkoutBtn}>
							<CheckoutButton>
								<button className={`${styles.cartBtn} ${styles.checkoutBtn}`}>
									Checkout
								</button>
							</CheckoutButton>
						</div>

						<div className={styles.styledPriceContainer}>
							<StyledPrice> EUR {totalPrice}</StyledPrice>
						</div>
					</>
				) : (
					"Shopping cart empty"
					// &&
					// setTimeout(() => {
					// 	setVisible(false)
					// }, 15500)
				)}
			</StyledButtonContainer>
		</StyledCart>
	)
}

export default Wrapper

const StyledCart = styled.div`
	@media (max-width: 768px) {
		z-index: -1;
		position: absolute;
		flex: 0 0 auto;
		width: 100vw;
	}

	&.hideCart {
		/* visibility: hidden; */
		display: none;
		opacity: 0;
		transition: 0.2s ease-in-out;
	}

	&.showCart {
		/* cart icon has index of 10 */
		z-index: 11;
		position: absolute;
		top: 0;
		right: 0;
		width: 35%;
		background-color: white;
		border-bottom: 1px solid black;
		border-left: 1px solid black;
		visibility: visible;
		opacity: 1;
		transition: 0.2s ease-in-out;
		@media (max-width: 1100px) {
			width: 42%;
		}
		@media (max-width: 768px) {
			width: 100%;
		}
	}
`
const StyledPrice = styled.p`
	font-size: 15px;
`

const StyledButtonContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	height: 6vh;
	align-items: center;
	padding: 1rem 0 1rem 1rem;
`
